import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"

import bgImg from "../images/bg.jpg"

const formUrl = `https://docs.google.com/forms/d/e/1FAIpQLSfxKnCdMp5Djjhf9VVf07gNel-DD9JfKCl4_ybtlUK1G5mcug/viewform`

const IndexPage = () => (
  <Layout>
    <SEO title="The Hungry Herb" />
    <div class="Home">
      <a href={formUrl}>
        <img src={bgImg} alt="Order now" />
      </a>
      <p>
        The Hungry Herb is a home grown brand from Manila. We use only fresh ingredients to make high quality hand-crafted chili-garlic sauces. All the herbs are grown in-house, with each bottle sealed for freshness before delivery.
        <br /><br />
        Our premium products can be used with a variety of dishes, or as an ingredient to staple recipes. No artificial preservatives were used, and the bottles can simply be kept at room temperature.
      </p>
      <a class="HomeBtn" href={formUrl}>Order Now</a>
    </div>
  </Layout>
)

export default IndexPage
